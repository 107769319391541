.update-password-container {
    display: flex;
    justify-content: space-between;
    border: 1px solid black;
    padding: 9px;
    align-items: center;
    margin-bottom: 1rem;
}

.password-form {
    padding-bottom: 2rem;
    border-radius: 2rem;
    font-size: 20px;
    box-shadow: 0 2px 0 rgb(0 0 0/5%);
}
