.timeline-toolbar-container {
    max-height: 75px;
    bottom: 0;
    position: fixed;
    left: 50%;
    padding: 10px;
    border-radius: 2em 2em 0 0;
    box-shadow: -2px -4px 12px #888888;
    -webkit-box-shadow: -2px -4px 12px #888888;
    transform: translateX(-50%);
    -webkit-transform: translateX(-50%);
}