.images {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    height: 200px;
}

.imagesWrapper {
    width: calc(50% - 0px);
}

.imageWrapper {
    width: 100%;
    height: auto;
    margin: 0 auto;
}

.image {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.img {
    border-radius: 0;
}

.default-box {
    background-color: #799BAF;
}