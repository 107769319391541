.toolbar-container {
    bottom: 0;
    position: fixed;
    left: 50%;
    margin: 0 auto;
    transform: translateX(-50%);
    background: #315663;
    border-radius: 2em 2em 0 0;
    box-shadow: -2px -4px 12px #888888;
    -webkit-box-shadow: -2px -4px 12px #888888;
}